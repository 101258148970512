var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kparow flex flex-row"},[(_vm.readonly)?_c('div',{staticStyle:{"width":"22px","height":"48px","margin":"0px 8px"}}):_c('div',{staticStyle:{"width":"22px","height":"48px","margin":"0px 8px"}},[_c('icon',{attrs:{"icon-name":_vm.trashIcon,"size":"kparow--icon"}})],1),(_vm.readonly)?_c('div',{staticClass:"kpainput kpainput--kpa flex flex-row",staticStyle:{"background-color":"#EEEEEE"}},[_c('span',{staticClass:"kpa__text"},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('icon',{staticStyle:{"width":"18px","height":"18px"},attrs:{"icon-name":_vm.lockIcon,"size":"kparow--icon"}})],1):_c('div',{staticClass:"kpainput kpainput--kpa flex flex-row"},[_c('span',{staticClass:"kpa__text"},[_vm._v(" "+_vm._s(_vm.text)+" ")])]),_c('div',{staticClass:"kpainput kpainput--obj"},[_c('span',{staticClass:"obj__text"},[_vm._v(" "+_vm._s(_vm.objectives)+" ")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"kpainput kpainput--weight flex"},[_c('span',{staticClass:"weight__text"},[_vm._v(" "+_vm._s(_vm.weight)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex",staticStyle:{"margin":"0px 0px 0px 14px"}},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 0 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 1 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 2 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 3 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 4 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 5 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpainput kpainput--criteria flex"},[_c('span',{staticClass:"criteria__text"},[_vm._v(" Criteria 6 ")])])
}]

export { render, staticRenderFns }