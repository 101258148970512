<template>
  <div class="kparow flex flex-row">
    <div
      style="width: 22px; height: 48px; margin: 0px 8px;"
      v-if="readonly"
    />
    <div
      style="width: 22px; height: 48px; margin: 0px 8px;"
      v-else
    >
      <icon
        :icon-name="trashIcon"
        size="kparow--icon"
      />
    </div>
    <div
      class="kpainput kpainput--kpa flex flex-row"
      style="background-color: #EEEEEE"
      v-if="readonly"
    >
      <span class="kpa__text"> {{ text }} </span>
      <icon
        :icon-name="lockIcon"
        size="kparow--icon"
        style="width: 18px; height: 18px"
      />
    </div>
    <div
      class="kpainput kpainput--kpa flex flex-row"
      v-else
    >
      <span class="kpa__text"> {{ text }} </span>
    </div>

    <div class="kpainput kpainput--obj">
      <span class="obj__text"> {{ objectives }} </span>
    </div>
    <div
      class="kpainput kpainput--criteria flex"
      style="margin: 0px 0px 0px 14px;"
    >
      <span class="criteria__text"> Criteria 0 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 1 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 2 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 3 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 4 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 5 </span>
    </div>
    <div class="kpainput kpainput--criteria flex">
      <span class="criteria__text"> Criteria 6 </span>
    </div>
    <div class="kpainput kpainput--weight flex">
      <span class="weight__text"> {{ weight }} </span>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  components: { Icon },
  props: ['icon1', 'icon2', 'text', 'readonly', 'objectives', 'weight'],
  data() {
    return {
      lockIcon: 'icon-lock',
      trashIcon: 'icon-trash',
    };
  },
};
</script>

<style scoped>
.kparow
{
  height: 88px;
  padding: 15px 8px;
}

.kparow--icon
{
 height: 22px;
 width: 22px;
 padding: 2px;
}

.kpainput
{
  height: 58px;
  border-radius: 5px;
  padding: 8px 15px;
  border: 1px solid #878E99;
  color: #321C3B;
  background-color: #FFFFFF;
 font-size: 15px;
 line-height: 120%;
 letter-spacing: 0.015em;
}

.kpainput--kpa
{
  width: 280px;
  margin: 0px 8px;
}

.kpainput--obj
{
  width: 378px;
  margin-left: 9px;
  font-size: 14px;
}

.kpainput--criteria
{
  width: 182px;
  margin: 0px 0px 0px 5px;
  justify-content: center;
}

.kpainput--weight
{
  width: 168px;
  justify-content: center;
  margin: 0px 0px 0px 14px;
}

.kpa__text
{
 width: 230px;
}

.obj__text
{
  width: 357px;
}

.criteria__text,.weight__text
{
  height: 19px;
  margin-top: 10px;
}
</style>
